<template>
  <div class="row">
    <div class="col-6 col-xs" v-for="com in allComs" :key="com.id">
      <ComCard
        :title="com.title"
        :send-at="com.sendAt"
        :com-type="com.comtype"
        :user-count="com.userCount"
      ></ComCard>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ComCard from "@/view/pages/Comcenter/ComCard";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_COMS_INFO } from "@/core/services/store/comcenter.module";

export default {
  name: "All",
  data() {
    return {
      coms: {}
    };
  },
  components: {
    ComCard
  },

  computed: {
    ...mapGetters(["getComs", "getPlayzoneId"]),
    allComs() {
      console.log(this.getComs);
      let coms = [];
      if (this.getComs.length > 0) {
        this.getComs.forEach(com => {
          const singleCom = {
            id: com.id,
            title: com.title,
            comtype: com.communication_type,
            userCount: com.recipients.length,
            sendAt: this.$moment(com.send_at).format("DD/MM/YY")
          };
          coms.push(singleCom);
        });
      }
      return coms;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Tous les jeux" }]);
    const payload = {
      playzoneId: this.getPlayzoneId,
      playzoneSlug:
        this.$route.params.pathMatch.split("/")[0] ||
        this.$route.params.pathMatch.replace(/^\/|\/$/g, "")
    };
    this.$store
      .dispatch(GET_COMS_INFO, payload)
      .then(data => {
        console.log(data);
      })
      .catch(err => console.log(err));
  }
};
</script>
