<template>
  <div class="card card-custom gutter-b">
    <div class="card-header game-header">
      <div class="card-title d-flex">
        <span>
          <img
            v-if="comType === 'Email'"
            alt=""
            src="/media/svg/icons/Communication/Mail.svg"
          />
          <img
            v-if="comType === 'SMS'"
            alt=""
            src="/media/svg/icons/Communication/Incoming-box.svg"
          />
        </span>
        <span>{{ comType }}</span>
      </div>
      <a
        class="card-toolbar flaticon-eye icon-2x text--white text--darken-2-3"
        href="#"
        >&nbsp;</a
      >
    </div>
    <div class="card-body">
      <div class="d-flex justify-content-start align-items-center">
        <div>
          <h3 class="game-name">
            {{ title }}
            <span class="small">
              <br />
              <span>Date de diffusion {{ sendAt }}</span>
            </span>
          </h3>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div
        class="d-flex justify-content-between align-items-center align-content-center footer-content"
      >
        <div
          class="d-flex justify-content-start align-items-center align-content-center footer-content"
        >
          <img alt="" src="/media/svg/icons/Communication/Incoming-mail.svg" />
          <span>{{ userCount }} Envoyé<span v-if="userCount > 1">s</span></span>
        </div>
        <div
          class="d-flex justify-content-start align-items-center align-content-center footer-content"
        >
          <img alt="" src="/media/svg/icons/Communication/Mail-error.svg" />
          <span>0 bloqué</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComCard",
  props: {
    title: {
      required: true,
      String
    },
    comType: String,
    sendAt: String,
    userCount: {
      Number,
      default: 0
    },
    imgUrl: {
      default: "/media/svg/icons/Communication/Mail.svg",
      String
    },
    status: String
  }
};
</script>
<style lang="scss" scoped></style>
